import Vue from 'vue'
import { ObserveVisibility } from 'vue-observe-visibility'
import IdleVue from 'idle-vue'
import { EventBus } from "@/utils/event-bus"
import IdleLayover from "@/helpers/IdleLayover"


export default async ({ app, $config }, inject) => {


  Vue.db.collection(`keys`).doc('aws').get().then((doc) => {
    if (doc.exists) {
      let tmp = doc.data()
      AWS.config.update({ accessKeyId: tmp.access, secretAccessKey: tmp.secret, region: "us-east-1" })
      Vue.docClient = Vue.prototype.$docClient = new AWS.DynamoDB.DocumentClient()
    }
  })




  Vue.directive('observe-visibility', ObserveVisibility)

  Vue.use(IdleVue, {
    eventEmitter: EventBus,
    idleTime: 60000 * 60 * 2,
    startAtIdle: false
  })
  Vue.component(IdleLayover)
}